import { useLocation, useNavigate } from 'react-router-dom';
import { TbUsers } from 'react-icons/tb';
import UserPopoutMenuItem from './UserPopoutMenuItem';
import useTenantContext from '../../features/dashboard/hooks/useTenantContext';

interface UserPopoutAdminMenuItemProps {
    isAdmin?: boolean;
}

const UserPopoutAdminMenuItem = ({ isAdmin }: UserPopoutAdminMenuItemProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tenant = useTenantContext();

    const handleAdminClick = () => {
        navigate(`/dashboard/admin?tenant=${tenant}`);
    };

    const handleDashboardClick = () => {
        navigate(`/dashboard?tenant=${tenant}`);
    };

    if (!isAdmin) return null;

    if (pathname === '/dashboard/admin') return <UserPopoutMenuItem label="SMARTR Cloud" onClick={handleDashboardClick} iconType={TbUsers} divider />;

    return <UserPopoutMenuItem label="User admin" onClick={handleAdminClick} iconType={TbUsers} divider badgeLabel="ADMIN" />;
};

export default UserPopoutAdminMenuItem;
