import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserInitialsCircle from './UserInitialsCircle';
import useAuth from '../../hooks/useAuth';
import { RxExit } from 'react-icons/rx';
import { TbUsers } from 'react-icons/tb';
import './UserAvatarPopout.css';
import UserPopoutMenuItem from './UserPopoutMenuItem';
import UserPopoutDivider from './UserPopoutDivider';
import UserPopoutAdminMenuItem from './UserPopoutAdminMenuItem';

interface UserAvatarPopoutProps {
    isMenuOpen: boolean;
}

const UserAvatarPopout: React.FC<UserAvatarPopoutProps> = ({ isMenuOpen }) => {
    const navigate = useNavigate();
    const { handleSignOut, tenants, user } = useAuth();
    const popoutRef = useRef<HTMLDivElement>(null);

    const handleTenantChange = () => {
        navigate('/tenants');
    };

    return (
        <div ref={popoutRef} className={`user-popout ${isMenuOpen ? 'visible' : ''}`}>
            <div className="user-popout-info">
                <UserInitialsCircle firstName={user?.firstName} lastName={user?.lastName} greyBackground={true} />
                <div className="user-popout-details">
                    <span className="user-popout-name">
                        {user?.firstName} {user?.lastName}
                    </span>
                    <span className="user-popout-email">{user?.email}</span>
                </div>
            </div>
            <p className="user-popout-subscription">
                Your workspace is on the <br />
                <strong>
                    SMARTR<span className="user-popout-trademark">®</span> Cloud
                </strong>{' '}
                subscription.
            </p>
            <UserPopoutDivider />

            {tenants.length > 1 && <UserPopoutMenuItem label="Change Tenant" onClick={handleTenantChange} iconType={TbUsers} divider />}

            <UserPopoutAdminMenuItem isAdmin={user?.adminUser} />

            <UserPopoutMenuItem label="Log Out" onClick={handleSignOut} iconType={RxExit} />
        </div>
    );
};

export default UserAvatarPopout;
