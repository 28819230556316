import React from 'react';
import './ModuleButton.css';
import getModuleIcon from '../utils/getModuleIcon';
import { capitaliseWord } from '../../../utils/string';
import { getModuleUrlWithParams } from '../utils/getModuleUrlWithParams';
import useTenantContext from '../hooks/useTenantContext';

export interface ModuleButtonProps {
    url: string;
    moduleType: string;
    disabled?: boolean;
}

const ModuleButton: React.FC<ModuleButtonProps> = ({ moduleType, url, disabled }) => {
    const iconComponent = React.createElement(getModuleIcon(moduleType), { size: '50px' });
    const name = capitaliseWord(moduleType);
    const tenant = useTenantContext();

    const handleNavigation = () => {
        const urlWithParams = getModuleUrlWithParams(url, moduleType);
        window.location.href = urlWithParams;
    };

    return (
        <div className={`module-button${disabled ? ' disabled' : ''}`}>
            <button onClick={handleNavigation} className="button-unstyled" role="link" aria-label={`Go to ${name} button`}>
                <div className="module-button-icon">{iconComponent}</div>
            </button>

            <div className="module-button-text">
                <button onClick={handleNavigation} className="button-unstyled" role="link" aria-label={`Go to ${name} link`}>
                    <p className="no-margin weight-bold inverse-color" data-testid="module-button-label">
                        {name}
                    </p>
                </button>
                <span className="module-button-info-text">{`${tenant || 'unknown'}-${moduleType}`}</span>
            </div>
        </div>
    );
};

export default ModuleButton;
