import { createElement } from 'react';
import { IconType } from 'react-icons';
import './UserTableRowOptionsItem.css';

interface UserTableRowOptionsItemProps {
    label: string;
    iconType: IconType;
    iconColor?: string;
    onClick: () => void;
}

const UserTableRowOptionsItem = ({ label, iconType, iconColor, onClick }: UserTableRowOptionsItemProps) => {
    return (
        <div className="user-table-row-options-item" onClick={onClick}>
            {createElement(iconType, { size: 18, color: iconColor })}
            <span>{label}</span>
        </div>
    );
};

export default UserTableRowOptionsItem;
