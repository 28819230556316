import { IoChevronDown } from 'react-icons/io5';
import useAuth from '../../hooks/useAuth';
import UserInitialsCircle from './UserInitialsCircle';
import UserAvatarPopout from './UserAvatarPopout';
import useTenantContext from '../../features/dashboard/hooks/useTenantContext';
import { useUserPopout } from '../../features/dashboard/hooks/useUserPopout';
import './UserBar.css';

const UserBar = () => {
    const { user } = useAuth();
    const { isMenuOpen, toggleMenu, initialsRef } = useUserPopout();
    const tenant = useTenantContext();

    return (
        <div className="user-bar">
            <div ref={initialsRef} style={{ display: 'flex' }} onClick={toggleMenu}>
                <UserInitialsCircle firstName={user?.firstName} lastName={user?.lastName} />
                <div className="user-info">
                    <div className="user-name">
                        {user?.firstName} {user?.lastName}
                        <div className="tenant-info">{tenant}</div>
                    </div>
                    <div className="popout-icon">
                        <IoChevronDown />
                    </div>
                </div>
                <UserAvatarPopout isMenuOpen={isMenuOpen} />
            </div>
        </div>
    );
};

export default UserBar;
