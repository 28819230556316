import { createElement } from 'react';
import { IconType } from 'react-icons';
import './FilterButton.css';

interface FilterButtonProps {
    children: string;
    iconType: IconType;
    onClick: () => void;
    colorType: 'primary' | 'secondary';
}

const FilterButton = ({ onClick, children, iconType, colorType }: FilterButtonProps) => {
    return (
        <button onClick={onClick} className={`filter-button ${colorType}`}>
            {createElement(iconType, { size: 20 })}
            {children}
        </button>
    );
};

export default FilterButton;
