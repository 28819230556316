import { IconType } from 'react-icons';
import { createElement } from 'react';
import './Widget.css';

interface WidgetProps {
    iconType: IconType;
}

const Widget = ({ iconType }: WidgetProps) => {
    return <div className="widget">{createElement(iconType, { color: '#001624', size: 18 })}</div>;
};

export default Widget;
