import React from 'react';
import ModulesSection from './ModulesSection';
import DashboardLoadingIndicator from './DashboardLoadingIndicator';
import DashboardHeroImage from './DashboardHeroImage';
import ExploreSolutionsButton from './ExploreSolutionsButton';
import useAuth from '../../../hooks/useAuth';
import useTenantContext from '../hooks/useTenantContext';
import useUserDataQuery from '../hooks/useUserDataQuery';
import smartrLogo from '../../../assets/smartr-logo.webp';
import UserBar from '../../../components/navigation/UserBar';
import useTenantModulesQuery from '../../../hooks/useTenantModulesQuery';
import './Dashboard.css';

const Dashboard: React.FC = () => {
    const { user } = useAuth();
    const tenant = useTenantContext();
    const { data: userData, isLoading, error } = useUserDataQuery();
    const { data: tenantModulesData, isLoading: isLoadingTenantModules, error: tenantModulesError } = useTenantModulesQuery(tenant);
    const firstName = user?.firstName || '';

    if (isLoading || isLoadingTenantModules) return <DashboardLoadingIndicator />;

    return (
        <>
            <UserBar />
            <div className="dashboard">
                <div className="dashboard-header">
                    <div className="dashboard-header-content">
                        <div className="dashboard-header-welcome-card">
                            <img src={smartrLogo} className="dashboard-header-smartr-logo" alt="SMARTR Logo" />
                            <h3>Welcome to the Cloud, {firstName}</h3>
                            <h4 className="dashboard-header-message">
                                Manage your account, get 24/7 support and access <br />
                                SMARTR technology platforms.
                            </h4>
                            <div>
                                <ExploreSolutionsButton />
                            </div>
                        </div>
                        <DashboardHeroImage />
                    </div>
                </div>

                <ModulesSection userModules={userData?.modules || []} tenantModules={tenantModulesData || []} error={error || tenantModulesError} />
            </div>
        </>
    );
};

export default Dashboard;
