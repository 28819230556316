import DashboardContainer from '../../components/layout/DashboardContainer';
import Dashboard from '../../features/dashboard/components/Dashboard';

const DashboardRoute = () => {
    return (
        <DashboardContainer>
            <Dashboard />
        </DashboardContainer>
    );
};

export default DashboardRoute;
