import React, { useState } from 'react';
import useUsers from '../hooks/useUsers';
import useDebounce from '../hooks/useDebounce';
import UserAdminModuleFilter from './UserAdminModuleFilter';
import SearchInput from './SearchInput';
import UserAdminTable from './UserAdminTable';
import useTenantContext from '../../dashboard/hooks/useTenantContext';
import FilterButton from './FilterButton';
import { GoPlus } from 'react-icons/go';
import useTenantModulesQuery from '../../../hooks/useTenantModulesQuery';
import './UserAdminList.css';

interface UserAdminListProps {
    onAddUserClick: () => void;
}

const UserAdminList = ({ onAddUserClick }: UserAdminListProps) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedModule, setSelectedModule] = useState<string | null>(null);
    const tenent = useTenantContext();

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const { data, isLoading, error } = useUsers({
        searchTerm: debouncedSearchTerm,
        moduleId: selectedModule,
        limit: 25,
    });

    const { data: modules, isLoading: isModulesLoading, error: modulesError } = useTenantModulesQuery(tenent);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleModuleSelection = (moduleId: string) => {
        setSelectedModule((currentState) => (currentState === moduleId ? null : moduleId));
    };

    const clearFilters = () => {
        setSelectedModule(null);
    };

    return (
        <>
            <div className="user-count-line">
                <div className="user-count-search-container">
                    <span className="sub-heading">{`Users (${data?.count ?? 0})`}</span>
                    <div className="user-admin-top-bar">
                        <SearchInput value={searchTerm} onChange={handleSearchChange} />
                        <UserAdminModuleFilter
                            clearFilters={clearFilters}
                            modules={modules || []}
                            selectedModule={selectedModule}
                            handleModuleSelection={handleModuleSelection}
                        />
                        <FilterButton iconType={GoPlus} onClick={onAddUserClick} colorType="primary">
                            Add User
                        </FilterButton>
                    </div>
                </div>
            </div>

            <UserAdminTable data={data} isLoading={isLoading} isModulesLoading={isModulesLoading} error={error} modulesError={modulesError} />
        </>
    );
};

export default UserAdminList;
