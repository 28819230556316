import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';
import User from '../types/user';

export interface UpdateUserModulesOptions {
    oktaUserId: string;
    moduleIds: string[];
}

export default async function updateUserModules({ oktaUserId, moduleIds }: UpdateUserModulesOptions) {
    return fetcher<User>(`${config.apiUrl}/users/${oktaUserId}/modules`, {
        method: 'PUT',
        body: { moduleIds },
    });
}
