import { Routes, Route, Navigate } from 'react-router-dom';
import LoginRoute from './LoginRoute';
import TenantSelectionRoute from './TenantSelectionRoute';
import ErrorBoundaryRoute from './ErrorBoundaryRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import DashboardRoute from './DashboardRoute';
import SecurityProvider from '../providers/SecurityProvider';
import LoginCallbackRoute from './LoginCallbackRoute';
import ForgottenPasswordRoute from './ForgottenPasswordRoute';
import AdminRoute from './AdminRoute';

const AppRoutes = () => {
    return (
        <SecurityProvider>
            <Routes>
                <Route path="/" Component={LoginRoute} />
                <Route path="/forgotten-password" Component={ForgottenPasswordRoute} />
                <Route path="login/callback" Component={LoginCallbackRoute} />
                <Route path="/logout" element={<Navigate to="/" replace />} />
                <Route Component={AuthenticatedRoute}>
                    <Route path="/tenants" Component={TenantSelectionRoute} />
                    <Route path="/dashboard" Component={DashboardRoute} />
                    <Route path="/dashboard/admin" Component={AdminRoute} />
                </Route>
                <Route path="/*" Component={ErrorBoundaryRoute} />
            </Routes>
        </SecurityProvider>
    );
};

export default AppRoutes;
