import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import LoginLayout from '../../../components/layout/LoginLayout';
import './DashboardLoadingIndicator.css';

const DashboardLoadingIndicator: React.FC = () => {
    return (
        <LoginLayout header="Just a moment... configuring workspace">
            <div className="dashboard-loading-indicator">
                <AiOutlineInfoCircle size="1rem" />
                <p className="no-margin">Creating cloud environment</p>
            </div>
        </LoginLayout>
    );
};

export default DashboardLoadingIndicator;
