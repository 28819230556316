import './TextInput.css';

interface TextInputProps {
    label: string;
    name: string;
    value: string;
    placeholder?: string;
    type?: React.HTMLInputTypeAttribute;
    onChange: (value: string) => void;
    tabIndex?: React.HTMLAttributes<HTMLInputElement>['tabIndex'];
    errorMessage?: string | null;
    disabled?: boolean;
}

const TextInput = ({ label, name, type, value, onChange, placeholder, tabIndex, errorMessage, disabled }: TextInputProps) => {
    return (
        <div className={`text-input${errorMessage ? ' error' : ''} `}>
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={(event) => onChange(event.target.value)}
                tabIndex={tabIndex}
                autoComplete="on"
                disabled={disabled}
            />
            {errorMessage && <p className="text-input-error-message">{errorMessage}</p>}
        </div>
    );
};

export default TextInput;
