import { useQuery } from '@tanstack/react-query';
import { fetchTenantModules } from '../api/fetchTenantModules';

export const FETCH_TENANT_MODULES_QUERY = 'fetch/tenant-modules';

const useTenantModulesQuery = (tenant: string | null) => {
    return useQuery({
        queryKey: [FETCH_TENANT_MODULES_QUERY, tenant],
        queryFn: () => fetchTenantModules(tenant),
        staleTime: 5 * 60 * 1000,
    });
};

export default useTenantModulesQuery;
