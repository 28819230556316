import './Badge.css';

interface BadgeProps {
    label: string;
}

const Badge = ({ label }: BadgeProps) => {
    return (
        <div className="badge">
            <span>{label}</span>
        </div>
    );
};

export default Badge;
