import { ReactNode } from 'react';
import LoginFooter from './LoginFooter';
import backgroundImage from '../../assets/login-page-background.webp';
import LoginFormContainer from './LoginFormContainer';
import './LoginLayout.css';

interface LoginLayoutProps {
    children: ReactNode;
    header: string;
}

const GRADIENT_STYLE = `linear-gradient(
    90deg,
    #001624 4.72%,
    rgba(0, 22, 36, 0.75) 55.04%,
    rgba(0, 22, 36, 0.42) 69.22%,
    rgba(0, 22, 36, 0.00) 96.74%
)`;

const LoginLayout = ({ children, header }: LoginLayoutProps) => {
    return (
        <div
            style={{
                backgroundImage: `${GRADIENT_STYLE}, url(${backgroundImage})`,
            }}
            className="login-page"
        >
            <div className="login-content">
                <LoginFormContainer header={header}>{children}</LoginFormContainer>
            </div>
            <LoginFooter />
        </div>
    );
};

export default LoginLayout;
