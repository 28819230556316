import './Toast.css';
import { BsCheck2Square } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';

interface ToastProps {
    message: string;
    onClose: () => void;
}

const Toast = ({ message, onClose }: ToastProps) => {
    return (
        <div className="toast">
            <span className="toast-message">
                <BsCheck2Square size={18} color="#34c39d" />
                <span>{message}</span>
            </span>
            <IoCloseOutline size={18} onClick={onClose} className="toast-close-button" />
        </div>
    );
};

export default Toast;
