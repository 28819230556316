import { UserApiResponse } from '../types/user.types';
import UserAdminTableRow from './UserAdminTableRow';
import './UserAdminTable.css';
import { useState } from 'react';

type UserAdminTableProps = {
    data: UserApiResponse | undefined;
    isLoading: boolean;
    isModulesLoading: boolean;
    error: Error | null;
    modulesError: Error | null;
};

const headings = ['User ID', 'Email', 'Platform Access', ''];

const UserAdminTable = ({ data, isLoading, isModulesLoading, error, modulesError }: UserAdminTableProps) => {
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

    const handleUserOptionsToggle = (userId: string) => {
        setSelectedUserId((currentState) => (currentState === userId ? null : userId));
    };

    if (isLoading || isModulesLoading) {
        return <div className="loading-error-message">Loading users...</div>;
    }
    if (error || modulesError) {
        return <div className="loading-error-message">Error loading users: {error?.message}</div>;
    }

    if (data?.results.length === 0) {
        return (
            <div className="centered">
                <h4>No results found</h4>
            </div>
        );
    }

    return (
        <table className="user-table">
            <thead>
                <tr>
                    {headings.map((heading) => (
                        <th key={heading}>{heading}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {(data?.results || []).map((user) => (
                    <UserAdminTableRow
                        key={user.oktaUserId}
                        user={user}
                        onClickUserOptions={handleUserOptionsToggle}
                        selectedUserId={selectedUserId}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default UserAdminTable;
