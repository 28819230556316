import './CompactCheckbox.css';

interface CompactCheckboxProps {
    label: string;
    name: string;
    checked: boolean;
    onChange: () => void;
}

const CompactCheckbox = ({ label, name, checked, onChange }: CompactCheckboxProps) => {
    return (
        <label className="compact-checkbox-container">
            {label}
            <input type="checkbox" name={name} id={name} checked={checked} onChange={onChange} />

            <span className="compact-checkmark">
                <span className="compact-checkmark-inner"></span>
            </span>
        </label>
    );
};

export default CompactCheckbox;
