import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';
import User from '../types/user';

export default async function createUser(user: User) {
    return fetcher<User>(`${config.apiUrl}/users`, {
        method: 'POST',
        body: user,
    });
}
