import { FormEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TextInput from '../../../components/ui/TextInput';
import Button from '../../../components/ui/Button';
import ForgottenPasswordFormButtons from './ForgottenPasswordFormButtons';
import useForgottenPasswordMutation from '../hooks/useForgottenPasswordMutation';
import './ForgottenPasswordForm.css';

/** state object is unknown this extracts the email if it exists */
const getEmailFromState = (state: unknown) => {
    if (!state || typeof state !== 'object') return '';
    if ('email' in state && typeof state.email === 'string') return state.email;
    return '';
};

const ForgottenPasswordForm = () => {
    const location = useLocation();
    const [email, setEmail] = useState(getEmailFromState(location.state));
    const forgottonPasswordMutation = useForgottenPasswordMutation();

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        forgottonPasswordMutation.mutate(email);
    };

    if (forgottonPasswordMutation.error) {
        return (
            <div className="forgotten-password-form">
                <p>{forgottonPasswordMutation.error.message || 'Failed to reset password, please retry or speak to your administrator.'}</p>
                <ForgottenPasswordFormButtons />
            </div>
        );
    }

    if (forgottonPasswordMutation.isSuccess) {
        return (
            <div className="forgotten-password-form">
                <p>
                    If <b>{email}</b> is found within our system, you will receive an email with instructions on how to reset your password.
                </p>
                <ForgottenPasswordFormButtons />
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="forgotten-password-form">
            <TextInput label="Email address" type="email" name="email" value={email} onChange={setEmail} placeholder="Email address" tabIndex={0} />
            <Button disabled={email === '' || forgottonPasswordMutation.isPending} label="Reset via email" type="submit" />
            <ForgottenPasswordFormButtons />
        </form>
    );
};

export default ForgottenPasswordForm;
