import DashboardContainer from '../../components/layout/DashboardContainer';
import UserAdmin from '../../features/user-admin/components/UserAdmin';
import { ToastProvider } from '../providers/ToastProvider';

const AdminRoute = () => {
    return (
        <DashboardContainer>
            <ToastProvider>
                <UserAdmin />
            </ToastProvider>
        </DashboardContainer>
    );
};

export default AdminRoute;
