import './TextInput.css';

interface TextInputSelectedProps {
    label: string;
    name: string;
    value: string;
    type?: React.HTMLInputTypeAttribute;
    onClickChange: () => void;
}

const TextInputSelected = ({ label, name, type, value, onClickChange }: TextInputSelectedProps) => {
    return (
        <div className="text-input text-input-selected" onClick={onClickChange} aria-label="change-user-button">
            <label htmlFor={name}>{label}</label>
            <input id={name} type={type} name={name} value={value} disabled autoComplete="off" />

            <div className="text-input-wrapper">
                <span aria-hidden="true">{value}</span>
                <span className="text-input-selected-change-btn">{'(Change)'}</span>
            </div>
        </div>
    );
};

export default TextInputSelected;
