import { ReactNode } from 'react';
import './Modal.css';

interface ModalProps {
    isOpen: boolean;
    children: ReactNode;
    handleClose: () => void;
}

const Modal = ({ isOpen, handleClose, children }: ModalProps) => {
    const handleModalContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" onClick={handleClose}>
            <div className="modal-content" onClick={handleModalContentClick}>
                {children}
            </div>
        </div>
    );
};

export default Modal;
