import { Navigate } from 'react-router-dom';
import LoginLayout from '../../components/layout/LoginLayout';
import LoginForm from '../../features/login/components/LoginForm';
import LoadingRoute from './LoadingRoute';
import useAuth from '../../hooks/useAuth';

const LoginRoute = () => {
    const { isAuthenticated, isLoading, tenants } = useAuth();

    if (isAuthenticated && tenants.length === 1) {
        const [tenant] = tenants;
        return <Navigate to={`/dashboard?tenant=${tenant}`} />;
    }

    if (isAuthenticated) return <Navigate to="/tenants" />;

    if (isLoading) return <LoadingRoute />;

    return (
        <LoginLayout header="Sign in to your cloud account">
            <LoginForm />
        </LoginLayout>
    );
};

export default LoginRoute;
