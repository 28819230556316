import { useOktaAuth } from '@okta/okta-react';
import { User } from '../types/user';
import { AuthApiError } from '@okta/okta-auth-js';
import { WebfingerRel, WebfingerResponse } from '../types/auth';
import logger from '../lib/logger';

const getErrorMessage = (errorCode = 400, message: string) => {
    switch (errorCode) {
        case 401:
            return `${message}, please check your credentials and retry.`;
        case 403:
            return `${message}, please check you have the correct permissions by contacting your administrator.`;
        default:
            return message;
    }
};

interface HandleSignInOptions {
    username: string;
    password: string;
}

/** Auth library abstraction hook */
const useAuth = () => {
    const { oktaAuth, authState } = useOktaAuth();

    const handleSignIn = async ({ username, password }: HandleSignInOptions) => {
        try {
            const transaction = await oktaAuth.signInWithCredentials({
                username,
                password,
            });

            if (transaction.status === 'SUCCESS') {
                await oktaAuth.token.getWithRedirect({
                    sessionToken: transaction.sessionToken,
                    scopes: ['openid', 'smartvue_meta', 'email', 'phone'],
                });
            } else {
                throw new Error(`We cannot handle the status ${transaction.status}`);
            }
        } catch (error) {
            const extractedErrorMessage = (error as AuthApiError).message;
            const statusCode = (error as AuthApiError).xhr?.status;
            const message = getErrorMessage(statusCode, extractedErrorMessage);

            // no need to log authentication errors
            if (statusCode !== 401 && statusCode !== 403) {
                logger.error('login attempt failed: ', error as AuthApiError);
            }

            throw new Error(message);
        }
    };

    const handleSignOut = () => {
        void oktaAuth.signOut();
    };

    /** requests for a password to be reset against given email */
    const handleForgottenPassword = async (email: string) => {
        try {
            const transaction = await oktaAuth.forgotPassword({
                username: email,
                factorType: 'EMAIL',
            });
            if (transaction.status === 'RECOVERY_CHALLENGE') return;
        } catch (error) {
            const extractedErrorMessage = (error as Error).message;
            logger.error('forgot password reset failed: ', error as Error);
            throw new Error(`${extractedErrorMessage}, failed to reset password please try again.`);
        }
    };

    /** redirects to okta login page or 3rd party idp */
    const handleRedirectToAuth = async (email: string) => {
        try {
            await oktaAuth.token.getWithRedirect({
                loginHint: email,
                scopes: ['openid', 'smartvue_meta', 'email', 'phone'],
            });
        } catch (error) {
            const extractedErrorMessage = (error as Error).message;
            logger.error('failed to redirect to auth provider: ', error as Error);
            throw new Error(`${extractedErrorMessage}, failed to redirect to auth provider please try again.`);
        }
    };

    /** Determines whether the user has a 3rd party Idp that requires an okta redirect */
    const requiresOktaWebsite = async (email: string) => {
        try {
            const response = (await oktaAuth.webfinger({
                resource: `acct:${email}`,
                rel: 'okta:idp',
            })) as WebfingerResponse;
            const idp = (response.links || []).find(({ rel }) => rel === WebfingerRel.ConfigurableIdp);
            return !!idp?.href;
        } catch (error) {
            // when error is thrown its easier to present the okta login by default
            return true;
        }
    };

    // Generics unsupported when using transformAuthState, user is a custom attribute (see oktaAuth.ts for usage)
    const user = authState?.user as User | undefined;

    const accessToken = authState?.accessToken?.accessToken;

    return {
        accessToken,
        handleForgottenPassword,
        handleRedirectToAuth,
        handleSignIn,
        handleSignOut,
        isAuthenticated: !!authState?.isAuthenticated,
        isLoading: !authState,
        requiresOktaWebsite,
        tenants: user?.tenants || [],
        user,
    };
};

export default useAuth;
