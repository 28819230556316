import React from 'react';
import { Link } from 'react-router-dom';
import './ExploreSolutionsButton.css';
import { MdArrowOutward } from 'react-icons/md';

const ExploreSolutionsButton: React.FC = () => {
    return (
        <Link to="https://smartr.build/" target="_blank" rel="noopener noreferrer" className="explore-solutions-button">
            <span className="explore-solutions-button-text">Explore More Solutions</span>
            <MdArrowOutward size={18} />
        </Link>
    );
};

export default ExploreSolutionsButton;
