import { useRef } from 'react';
import { PiDotsThreeVerticalBold } from 'react-icons/pi';
import { CiEdit } from 'react-icons/ci';
// import { FiUserX } from 'react-icons/fi';
import UserTableRowOptionsItem from './UserTableRowOptionsItem';
import './UserTableRowOptions.css';
import useOutsideClick from '../../../hooks/useOutsideClick';

interface UserTableRowOptionsProps {
    isOptionsOpen: boolean;
    onClickUserOptions: () => void;
    onEditUserClick: () => void;
}

const UserTableRowOptions = ({ isOptionsOpen, onClickUserOptions, onEditUserClick }: UserTableRowOptionsProps) => {
    const impactRef = useRef<HTMLDivElement>(null);

    useOutsideClick(impactRef, onClickUserOptions);

    return (
        <div className="user-table-row-options">
            <PiDotsThreeVerticalBold size={30} onClick={onClickUserOptions} className="user-table-row-options-button" />
            {isOptionsOpen && (
                <div className="user-table-row-options-menu" ref={impactRef}>
                    <UserTableRowOptionsItem label="Edit User Info" iconType={CiEdit} onClick={onEditUserClick} />
                    {/* TODO: implement on delete user ticket
                    <hr className="user-table-row-options-menu-divider" />
                    <UserTableRowOptionsItem label="Delete User" iconType={FiUserX} iconColor="#B91C1C" onClick={handleDeleteUserClick} /> */}
                </div>
            )}
        </div>
    );
};

export default UserTableRowOptions;
