import { useQuery } from '@tanstack/react-query';
import { fetchUsers } from '../api/users';
import { FetchUsersParams } from '../types/user.types';

export const FETCH_USERS_QUERY_KEY = 'fetch/users';

const useUsers = ({ startId, limit, searchTerm, moduleId }: FetchUsersParams) => {
    return useQuery({
        queryKey: [FETCH_USERS_QUERY_KEY, startId, limit, searchTerm, moduleId],
        queryFn: () => fetchUsers({ startId, limit, searchTerm, moduleId }),
    });
};

export default useUsers;
