import { ReactNode } from 'react';
import TenantContext from '../../contexts/TenantContext';
import { useSearchParams } from 'react-router-dom';

interface TenantContextProviderProps {
    children: ReactNode;
}

const TenantContextProvider = ({ children }: TenantContextProviderProps) => {
    const [searchParams] = useSearchParams();
    const tenant = searchParams.get('tenant');
    return <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>;
};

export default TenantContextProvider;
