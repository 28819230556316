import { ReactNode } from 'react';
import TenantContextProvider from '../../app/providers/TenantContextProvider';
import './DashboardContainer.css';

interface DashboardContainerProps {
    children: ReactNode;
}

const DashboardContainer = ({ children }: DashboardContainerProps) => {
    return (
        <TenantContextProvider>
            <div className="dashboard-container">{children}</div>
        </TenantContextProvider>
    );
};

export default DashboardContainer;
