import { useState, useCallback, useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';

export const useUserPopout = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const initialsRef = useRef<HTMLDivElement>(null);

    const toggleMenu = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        setIsMenuOpen((prev) => !prev);
    }, []);

    useOutsideClick(initialsRef, () => setIsMenuOpen(false));

    return {
        isMenuOpen,
        setIsMenuOpen,
        toggleMenu,
        initialsRef,
    };
};
