import React from 'react';
import { SlMagnifier } from 'react-icons/sl';
import './SearchInput.css';

interface SearchInputProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, className }) => {
    return (
        <div className="search-input-container">
            <SlMagnifier className="search-icon" />
            <input
                type="text"
                placeholder="Search by email or User ID"
                value={value}
                onChange={onChange}
                className={`search-input ${className || ''}`}
            />
        </div>
    );
};

export default SearchInput;
