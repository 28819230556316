import ModuleButton from './ModuleButton';
import { Module } from '../../../types/module';
import InfoIndicator from '../../../components/ui/InfoIndicator';

interface ModulesListProps {
    userModules: Module[];
    tenantModules: Module[];
    error: Error | null;
}

const ModulesList = ({ userModules, tenantModules, error }: ModulesListProps) => {
    if (error) return <InfoIndicator title="Error" message={error.message} />;

    if (tenantModules.length === 0) {
        return (
            <InfoIndicator
                title="No applications"
                message="Your account does not have any applications available at the moment. Please contact the SMARTR team for assistance."
            />
        );
    }

    const userModuleTypes = userModules.map((module) => module.moduleType);

    return (
        <div className="modules-buttons">
            {tenantModules.map((tenantModule) => {
                const { id, moduleType, url } = tenantModule;
                const disabled = !userModuleTypes.includes(moduleType);
                return <ModuleButton key={id} moduleType={moduleType} url={url} disabled={disabled} />;
            })}
        </div>
    );
};

export default ModulesList;
