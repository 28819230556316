import smartrLogo from '../../assets/smartr-logo.webp';
import smartrLogoMobile from '../../assets/smartr-logo-mobile.webp';
import './LoginFormHeader.css';

const LoginFormHeader = () => {
    return (
        <div className="login-form-header">
            <div className="login-form-logos-desktop">
                <img src={smartrLogo} className="smartr-login-logo" alt="SMARTR Logo" />
            </div>

            <div className="login-form-logo-wrapper">
                <img src={smartrLogoMobile} alt="SMARTR Logo Mobile" className="smartr-login-logo-mobile" />
            </div>
        </div>
    );
};

export default LoginFormHeader;
