import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';
import { FetchUsersParams, UserApiResponse } from '../types/user.types';

export const fetchUsers = async (params: FetchUsersParams): Promise<UserApiResponse> => {
    const queryParams = new URLSearchParams();

    if (params.searchTerm) {
        queryParams.append('searchTerm', params.searchTerm);
    }

    if (params.moduleId) {
        queryParams.append('moduleId', params.moduleId);
    }

    if (params.limit) {
        queryParams.append('limit', params.limit.toString());
    }

    const queryString = queryParams.toString();

    return fetcher<UserApiResponse>(`${config.apiUrl}/users?${queryString}`, { method: 'GET' });
};
