import './InfoIndicator.css';

interface InfoIndicatorProps {
    title: string;
    message: string;
}

const InfoIndicator = ({ title, message }: InfoIndicatorProps) => {
    return (
        <div className="info-indicator">
            <h3 className="centered">{title}</h3>
            <h4 className="centered">{message}</h4>
        </div>
    );
};

export default InfoIndicator;
