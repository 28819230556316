import { useRef, useState } from 'react';
import { CiFilter } from 'react-icons/ci';
import { Module } from '../types/user.types';
import UserAdminListDivider from './UserAdminListDivider';
import { capitaliseWord } from '../../../utils/string';
import FilterButton from './FilterButton';
import CompactCheckbox from './CompactCheckbox';
import './UserAdminModuleFilter.css';
import useOutsideClick from '../../../hooks/useOutsideClick';

type UserAdminModuleFilterProps = {
    clearFilters: () => void;
    modules: Module[];
    selectedModule: string | null;
    handleModuleSelection: (moduleId: string) => void;
};

const UserAdminModuleFilter = ({ clearFilters, modules, selectedModule, handleModuleSelection }: UserAdminModuleFilterProps) => {
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const impactRef = useRef<HTMLDivElement>(null);

    const toggleFilterDropdown = () => {
        setIsFilterOpen((currentState) => !currentState);
    };

    useOutsideClick(impactRef, toggleFilterDropdown);

    return (
        <div className="filter-container">
            <FilterButton iconType={CiFilter} onClick={toggleFilterDropdown} colorType="secondary">
                Filter
            </FilterButton>
            {isFilterOpen && (
                <div className="filter-dropdown" ref={impactRef}>
                    <div className="filter-dropdown-header">
                        <CiFilter size={24} className="filter-button-icon" />
                        <button className="clear-filters-button" onClick={clearFilters}>
                            Clear All
                        </button>
                    </div>
                    <ul>
                        {modules?.map((module: Module) => (
                            <li key={module.id} className="user-admin-list-item">
                                <CompactCheckbox
                                    label={capitaliseWord(module.moduleType)}
                                    name={module.id}
                                    checked={selectedModule === module.id}
                                    onChange={() => handleModuleSelection(module.id)}
                                />
                                <UserAdminListDivider />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserAdminModuleFilter;
