import dashboardHeroImage from '../assets/dashboard-hero.webp';
import './DashboardHeroImage.css';

const DashboardHeroImage = () => {
    return (
        <div className="dashboard-img-container">
            <div className="dashboard-img" style={{ backgroundImage: `url(${dashboardHeroImage})` }} />
        </div>
    );
};

export default DashboardHeroImage;
