import React from 'react';
import './UserAdminModuleBubbles.css';

interface UserAdminListBubbleProps {
    label: string;
    isSmall?: boolean;
}

const UserAdminListBubble: React.FC<UserAdminListBubbleProps> = ({ label, isSmall = false }) => {
    return <div className={`bubble ${isSmall ? 'small-bubble' : ''} weight-bold`}>{label}</div>;
};

export default UserAdminListBubble;
