import React from 'react';
import { IconType } from 'react-icons';
import UserPopoutDivider from './UserPopoutDivider';
import Badge from '../ui/Badge';
import './UserPopoutMenuItem.css';

interface UserPopoutMenuItemProps {
    label: string;
    iconType: IconType;
    divider?: boolean;
    onClick: () => void;
    /** shows badge component hides if undefined */
    badgeLabel?: string;
}

const UserPopoutMenuItem = ({ label, iconType, onClick, divider, badgeLabel }: UserPopoutMenuItemProps) => {
    return (
        <>
            <div className="user-popout-menu-item" onClick={onClick}>
                {label}
                <div className="user-popout-menu-item-icon">
                    {badgeLabel && <Badge label={badgeLabel} />}
                    {React.createElement(iconType, { size: 18 })}
                </div>
            </div>
            {divider && <UserPopoutDivider />}
        </>
    );
};

export default UserPopoutMenuItem;
