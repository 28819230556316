import { useState, ReactNode, useCallback } from 'react';
import Toast from '../../components/ui/Toast';
import ToastContext from '../../contexts/ToastContext';

interface ToastState {
    message: string;
    visible: boolean;
}

const DEFAULT_STATE: ToastState = {
    message: '',
    visible: false,
};

export const ToastProvider = ({ children }: { children: ReactNode }) => {
    const [toast, setToast] = useState<ToastState>(DEFAULT_STATE);

    const resetToast = useCallback(() => {
        setToast(DEFAULT_STATE);
    }, []);

    const showToast = (message: string) => {
        setToast({ message, visible: true });
        setTimeout(resetToast, 3000);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toast.visible && <Toast message={toast.message} onClose={resetToast} />}
        </ToastContext.Provider>
    );
};
