import { useEffect } from 'react';

/**
 * Custom hook that triggers a callback when a click occurs outside the referenced element.
 *
 * @param {React.RefObject<HTMLElement>} ref - The ref object pointing to the element that should be checked for outside clicks.
 * @param {() => void} callback - The callback function to invoke when a click outside is detected.
 */
const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void): void => {
    useEffect(() => {
        const handleClickOutside = (evt: MouseEvent): void => {
            if (ref.current && !ref.current.contains(evt.target as Node)) callback();
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

export default useOutsideClick;
